import React from "react";
import "./Navbar.css";

export const Navbar = () => {
    return (
        <div className="nav-bg">
            <div className = "main-nav">
                <a href={"/"} style={{textDecoration: "none"}}>
                    <div className="aurelius-llc"> Aurelius LLC </div>
                </a>
                <div className="button-wrap">
                  <button onClick={(e) => {
                      window.location.href = 'mailto:contact@appsbyaurelius.com';
                      e.preventDefault();
                    }}
                    className="contact-btn">Contact Us 
                  </button>
                </div>
            </div>
        </div>            
    );
};
