export const ScreensContainer = () => {
  return (
    <div className="screens-container screens-container-sm">
      <div className="overlap-group">
        <img
          className="app-screenshot"
          alt="App Screenshot"
          src="app_screenshot_1.png"
        />
        <img
          className="app-img"
          alt="App Screenshot"
          src="app_screenshot_3.png"
        />
        <div className="rectangle"></div>
      </div>
    </div>
  );
};
