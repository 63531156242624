import React from "react";
import "./MainElements.css";
import { ScreensContainer } from "../ProductFeature";

export const MainElements = () => {
  return (
    <div className="container">
      <div className="main-elements-div">
        <div className="text-container">
          <div className="trackr-title">
            Trackr
            <span className="coming-soon">
              Coming Soon!
            </span>
          </div>
          <div className="trackr-subtext-one">Built-in accountability.</div>
          <div className="trackr-subtext-two">
            Don't go on the journey of self improvement alone. With goal sharing
            in Trackr, you'll have friends to support you along every step.
          </div>
          <div className="trackr-subtext-end">
            Make progress <em>together</em>.
          </div>
        </div>

        <div className="screens-container">
          <div className="overlap-group">
            <img
              className="app-screenshot"
              alt="App Screenshot"
              src="app_screenshot_1.png"
            />
            <img
              className="app-img"
              alt="App Screenshot"
              src="app_screenshot_3.png"
            />
            <div className="rectangle"></div>
          </div>
        </div>
      </div>
      <ScreensContainer></ScreensContainer>
      <div className="line-svg">
        <img
          className="progress-line"
          alt="Progress Line"
          src="progress-line-gradient.svg"
        />
      </div>
    </div>
  );
};
